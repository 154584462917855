import { Injectable } from '@angular/core';
import { Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { CompanyStateService } from '@dougs/company/shared';
import { URL } from '../const/routing.const';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(
    private readonly router: Router,
    private readonly companyStateService: CompanyStateService,
  ) {}

  createUrl(url: string[], withCompanyId = true, navigationsExtras?: UrlCreationOptions): UrlTree {
    return this.router.createUrlTree(
      [URL.COMPANY_PREFIX, withCompanyId ? this.companyStateService.activeCompany.id.toString() : '', ...url],
      navigationsExtras,
    );
  }
}
