import { URL } from '../const/routing.const';

export const getUrlParamsAsCommandArray = (url: string): any[] => {
  return url.split('?')[0].split('/').slice(3) || [URL.ACCOUNTING];
};

export const createRelativeUrlAsCommandArray = (ownerId: string, params?: any[]): any[] => {
  return ['/', URL.COMPANY_PREFIX, ownerId, ...(params ?? [])];
};

export const replaceCompanyIdInUrlAsCommand = (newOwnerId: string, url: string): any[] => {
  const params: any[] = getUrlParamsAsCommandArray(url);
  return createRelativeUrlAsCommandArray(newOwnerId, params);
};
