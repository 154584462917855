import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class QueryParamsRoutingService {
  constructor(private readonly router: Router) {}

  public async updateQueryParams(queryParamsKey: string, queryParamsValue: string | null): Promise<void> {
    await this.router.navigate([], {
      queryParams: {
        [queryParamsKey]: queryParamsValue,
      },
      queryParamsHandling: 'merge',
    });
  }
}
