import { Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, map, Observable, startWith } from 'rxjs';
import { replaceCompanyIdInUrlAsCommand } from '../utils/routing.utils';

@Pipe({
  name: 'replaceActiveCompanyIdInUrl',
  standalone: true,
})
export class ReplaceActiveCompanyIdInUrlPipe implements PipeTransform {
  constructor(private readonly router: Router) {}
  transform(activeCompanyId: string | number): Observable<string[]> {
    return this.router.events.pipe(
      startWith(''),
      map((events) => (events instanceof NavigationEnd ? events.url : this.router.url)),
      distinctUntilChanged(),
      map((url) => replaceCompanyIdInUrlAsCommand(activeCompanyId.toString(), url)),
    );
  }
}
