import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingService } from '../services/routing.service';

@Pipe({
  name: 'createUrl',
  standalone: true,
})
export class RoutingPipe implements PipeTransform {
  constructor(
    private readonly routingService: RoutingService,
    private readonly companyStateService: CompanyStateService,
  ) {}
  transform(page: string | string[], withUserId = true): Observable<string> {
    return this.companyStateService.activeCompanyIdChanged$.pipe(
      map(() => this.routingService.createUrl(Array.isArray(page) ? page : [page], withUserId).toString()),
    );
  }
}
